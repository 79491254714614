import './App.css';
import Comm from './components/Comm';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Mainone from './components/Mainone';
import Maintwo from './components/Maintwo';
// import Nav from './components/Nav';
import Twitter from './components/Twitter';


function App() {
  return (
    <div className='app'>
        <div className='nav'>
        <nav>
            <img src='/images/logo11.PNG' alt='LOGO'/>
            <ul>
                <li><a href='#2'>Story</a></li>
                <li><a href='#4'>Tokenomics</a></li>
                <li><a href='#5'>Community</a></li>
                <li><a href='#6'>Contact SID</a></li>
            </ul>
        </nav>
    </div>
      <Hero/>
      <span id='2'>
      <Mainone/>
      </span>
      <span id='3'>
      <Twitter/>
      </span>
      <span id='4'>
      <Maintwo/>
      </span>
      <span id='5'>
      <Comm/>
      </span>
      <span id='6'>
      <Footer/>
      </span>
    </div>
  );
}

export default App;
