import React from 'react'
import { useState, useEffect } from 'react';

const Mainone = () => {
    const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 699px)');

    // Set initial state based on media query
    setIsMobile(mediaQuery.matches);

    // Add listener for changes in the media query
    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener('change', handleMediaQueryChange);

    // Cleanup function to remove the listener when component unmounts
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);
  return (
    <div className='features'>
    <section>
        <h1>
            CAN I TELL YOU MY STORY?  
        </h1>
        <article>
            <main>
            {
                isMobile ? <img alt='IMG' src='./images/about2.PNG' /> : <img alt='IMG' src='./images/about.PNG' />
            }
            </main>
            <main>
                <p>
                After been stuck in ice for hundreds of years , i came out one day and I thought “ they left without me , they do this every year, doesn’t anybody love me, isn’t there anyone who cares about sid the sloth??” 
                <br/><br/>I wondered about and found  “AVAX” , I love it here! and since my life has been better! I miss my old frendss manny and Diego ,can we be friends? … but on AVAX?
                </p>
            </main>
        </article>
    </section>
    </div>
  )
}

export default Mainone