import React from 'react'

const Comm = () => {
  return (
    <div className='comm'>
      <section>
          <h1>
            Community 
          </h1>
          <article>
          <img alt='' src='/images/m1.JPG'/>
            <img alt='' src='/images/m2.JPG'/>
            <img alt='' src='/images/m3.PNG'/>
           <img alt='' src='/images/m4.PNG'/>
           <img alt='' src='/images/m5.PNG'/>
           <img alt='' src='/images/m6.JPG'/>
           {/* <ul>
            <li><img alt='' src='/images/m1.JPG'/></li>
            <li><img alt='' src='/images/m2.JPG'/></li>
            <li><img alt='' src='/images/m3.PNG'/></li>
           </ul>
           <ul>
            <li><img alt='' src='/images/m4.PNG'/></li>
            <li><img alt='' src='/images/m5.PNG'/></li>
            <li><img alt='' src='/images/m6.JPG'/></li>
           </ul> */}
          </article>
      </section>
    </div>
  )
}

export default Comm