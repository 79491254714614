import React from 'react'

const Maintwo = () => {
  return (
    <div className='token'>
        <section>
            <h1>
                TOKENOMICS 
            </h1>
            <article>
            <ul>
                <li>
                    <h3>TOTAL SUPPLY</h3>
                    <p>100,000</p>
                </li>
                <li>
                    <h3>TAX</h3>
                    <p>0/0</p>
                </li>
                <li>
                    <h3>LP LOCKED</h3>
                    <p>100%</p>
                </li>
                <li>
                    <h3>OWNERSHIP RENOUNCED</h3>
                </li>
            </ul>
            <main>
                <img alt='' src='/images/nomics.PNG' className='nomImage' />
            </main>
            </article>
        </section>
    </div>
  )
}

export default Maintwo