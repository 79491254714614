import React from 'react'

const Footer = () => {
  return (
    <div>
    <footer>
    <img src='/images/logo.PNG' alt='IMG' className='flogo'/>
        <h1>
            All my friends meet up here to chill
        </h1>
        <span>
            <a href='https://x.com/sidonavax?s=21&t=kXSL75OVsMUlPYQdvlHFpg'><img alt='X' src='./images/X.png'/></a>
            <a href='https://t.me/sid_avax'><img alt='Telegram' src='./images/telegram.png' /></a>
        </span>
    </footer>
    </div>
  )
}

export default Footer