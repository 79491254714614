import React from 'react'

const Twitter = () => {
  return (
    <div className='tweet'>
        <section>
            <img alt='tweet' src='./images/tweet.PNG' />
        </section>
    </div>
  )
}

export default Twitter