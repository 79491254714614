import React from 'react'
import './Components.css'


const Hero = () => {
  return (
    <div className='hero'>
        <section>
            <main>
                <img alt='LOGO' src='/images/a.PNG' />
                <img alt='LOGO' src='/images/comment.PNG' className='comment' />
            </main>
            <article>
                <h1>
                    SID THE SLOTH |<b style={{color: '#e84142'}}> $AVAX</b> 
                </h1>
                <p>
                    {/* The First ever Governance reward token on AVAX  */}
                </p>
                <strong>
                    Contract address 
                </strong>
                <span>
                    <b>0x9AB46E651095CDbb8E91B00991B2534659E5ac81</b>
                    <a href='https://dexscreener.com/avalanche/0x943ccbea5a3d5040ed564889a0660f9b0810beb3'>Chart</a>
                </span>
            </article>
        </section>
    </div>
  )
}

export default Hero